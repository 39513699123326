import { calculateCoverageFee } from '../components/quoting/powerups/utils';
import { DISCOUNT_MODIFIER_TYPE } from '../constants';
import {
  apiCall,
  apiCallPurchase,
  doNothing,
  getQuoteClientType,
} from '../util';

/**
 * Send lead with pet data, used on quote rate created
 * @param {Object} pet - The first pet when quote is created
 */
export function sendFSCLead({
  age = 0,
  breed = '',
  breedId = 0,
  email = '',
  gender = '',
  marketingOpt = false,
  petName = '',
  petType = '',
  promoCode,
  state,
  zipCode = '',
}) {
  return async (_, getState) => {
    const { quoting: { saveQuote: { quoteId } } } = getState();
    const url = 'FSCLead';
    const body = {
      age,
      breed,
      breedId,
      email,
      gender,
      marketingOpt,
      petName,
      petType,
      promoCode,
      quoteId,
      state,
      zipCode,
    };

    try {
      let stateAbbr = state;
      if (!state) {
        const response =
          await apiCall(`api/State/GetStateByZipCode/${zipCode}`);
        const payload = await response.json();
        stateAbbr = payload?.Data?.StateAbbr || '';
      }
      await apiCallPurchase(url, {
        post: {
          ...body,
          state: stateAbbr,
        },
      });
    } catch (error) {
      doNothing();
    }
  };
}

/**
 * Send lead with customer data, used after filling the Your info form
 * @param {Object} customer - The customer and secondary parent info
 */
export function sendFSCCustomerLead({
  email = '',
  firstName = '',
  lastName = '',
  phoneNumber = '',
  address = {
    city: '',
    line1: '',
    line2: '',
    state: '',
  },
  secondaryPetParent = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
  },
}) {
  return async (_, getState) => {
    const {
      quoting: {
        quoteSelectedValues,
        saveQuote: { quoteId },
      },
    } = getState();
    const url = 'FSCLead/customer';
    const body = {
      address,
      email,
      firstName,
      lastName,
      pets: quoteSelectedValues.map((pet) => ({ petName: pet.petName })),
      phoneNumber,
      quoteId,
      secondaryPetParent,
    };

    try {
      await apiCallPurchase(url, { post: body });
    } catch (error) {
      doNothing();
    }
  };
}

const getModifierData = ({ modifierId, quotingState, quoteId }) => {
  const {
    data,
    quoteSelectedValues,
  } = quotingState;

  const { petQuoteResponseList } = data;

  const petQuote = petQuoteResponseList
    .find((item) => item.petQuoteId === quoteId);
  const quoteSelected = quoteSelectedValues
    .find((item) => item.quoteId === quoteId)
    || { petName: 'Pet Name' };

  if (!petQuote || !quoteSelected) {
    return 0;
  }

  const powerUp = petQuote.InsuranceModifiers
    .find((modifier) => {
      if (modifier.Id !== modifierId
        && modifier?.BundleInsuranceModifiers?.length) {
        const bundleModifier = modifier.BundleInsuranceModifiers
          .find((bmodifier) => bmodifier.Id === modifierId);
        if (bundleModifier) {
          return bundleModifier;
        }
      }
      return modifier.Id === modifierId;
    });

  const powerUpPrice = calculateCoverageFee({
    data,
    petQuote,
    petQuoteSelected: quoteId,
    powerUp,
    quoteSelected,
  });

  return {
    amount: Number(powerUpPrice),
    name: powerUp.AppText,
    type: 'Powerup',
  };
};

const getPlanName = ({ selectedPlanPackaged, quoteInfo }) => {
  const planInfo = quoteInfo.PrePackagedPlans
    .find((plan) => plan.Id === selectedPlanPackaged);
  return planInfo?.Name || 'Custom plan';
};

const getTotalDiscount = (allDiscounts) => allDiscounts
  .reduce((accumulator, current) => {
    try {
      const { InsuranceModifierDiscount: { Discount, DiamondName } } = current;
      const percentage = Number(String(Discount)
        .replace('%', '')) || 0;

      const text = accumulator.discountGroup
        ? `${accumulator.discountGroup}, ${DiamondName}`
        : DiamondName;
      return {
        discount: accumulator.discount + percentage,
        discountGroup: text,
      };
    } catch (e) {
      return accumulator;
    }
  }, { discount: 0, discountGroup: '' });

/**
 * Send lead with quotes data, used once when user finish to customize quote
 */
export function sendFSCQuoteLead() {
  return async (_, getState) => {
    const url = 'FSCLead/quote';
    const { quoting } = getState();
    try {
      const {
        data: { petQuoteResponseList },
        partners,
        quoteSelectedValues,
        saveQuote: { quoteId },
      } = quoting;

      const quotes = quoteSelectedValues.map((quote) => {
        const petQuote = petQuoteResponseList
          .find((item) => item.petQuoteId === quote.quoteId);

        if (!petQuote) { return null; }

        const modifiers = quote.modifiers
          .filter((modifier) => modifier.isSelected)
          .map((modifier) => getModifierData({
            modifierId: modifier.id,
            quoteId: quote.quoteId,
            quotingState: quoting,
          }));

        const discountModifiers = petQuote.InsuranceModifiers
          .filter((modifier) => modifier.InsuranceModifierTypeId
            === DISCOUNT_MODIFIER_TYPE && modifier.IsSelected);
        const discountResume = getTotalDiscount(discountModifiers);

        const { reimbursementPercentage } = quote;
        const reimbursement = Number(reimbursementPercentage.replace(/%/g, ''));
        const coinsurance = 100 - reimbursement;

        return {
          annualMax: Number(quote.selectedPlanAmount.replace(/\$|,/g, ''))
            || 0,
          Coinsurance: coinsurance,
          deductible: Number(quote.deductibleQuantity.replace(/\$|,/g, '')),
          modifiers,
          petName: quote.petName,
          planPrice: quote.totalMontly,
          policyPlan: getPlanName({
            quoteInfo: petQuote,
            selectedPlanPackaged: quote.selectedPlanPackaged,
          }),
          quoteType: getQuoteClientType(partners),
          ...discountResume,
        };
      }).filter((quote) => quote);

      const body = {
        email: quoting.newCustomer.email || quoting.parameters.email,
        lastName: quoting.newCustomer.lastName || '',
        membershipLevel: quoting?.partners
          ?.costcoMembership?.membershipTypeName || '',
        membershipNumber: quoting?.partners
          ?.costcoMembership?.membershipNumber || '',
        membershipPartner: quoting?.partners?.data.PartnerName || '',
        quoteId,
        quotes,
      };
      await apiCallPurchase(url, { post: body });
    } catch (error) {
      doNothing();
    }
  };
}

/**
 * Send lead when potential client updates payment
 */
export function sendFSCPaymentLead() {
  return async (_, getState) => {
    const url = 'FSCLead/payment';
    const { quoting } = getState();
    try {
      const {
        quoteSelectedValues,
        newCustomer,
        saveQuote: { quoteId },
      } = quoting;

      const body = {
        email: quoting.newCustomer.email || quoting.parameters.email,
        lastName: newCustomer.lastName,
        pets: quoteSelectedValues.map((pet) => ({ petName: pet.petName })),
        quoteId,
      };
      await apiCallPurchase(url, { post: body });
    } catch (error) {
      doNothing();
    }
  };
}

/**
 * Send lead when client completes purchase
 */
export function sendFSCPurchaseLead(successfulPurchases = []) {
  return async (_, getState) => {
    const url = 'FSCLead/purchase';
    const { quoting } = getState();
    try {
      const {
        newCustomer,
        saveQuote: { quoteId },
      } = quoting;

      const body = {
        email: quoting.newCustomer.email || quoting.parameters.email,
        lastName: newCustomer.lastName,
        pets: successfulPurchases.map((pet) => ({ petName: pet.PetName })),
        quoteId,
      };
      await apiCallPurchase(url, { post: body });
    } catch (error) {
      doNothing();
    }
  };
}

/**
 * Send lead when client updates the SMS Alerts
 * @param {Object} param
 * @param {string} param.phoneNumber
 */
export function sendFSCMarketingLead({ phoneNumber }) {
  return async (_, getState) => {
    const url = 'FSCLead/marketing';
    const { quoting } = getState();
    try {
      const {
        newCustomer,
        saveQuote: { quoteId },
        sessionInformation: {
          nopCommerceUser: {
            DiamonClientdId,
          },
        },
      } = quoting;

      const body = {
        customerId: String(DiamonClientdId),
        email: newCustomer.email || quoting.parameters.email,
        lastName: newCustomer.lastName || '',
        quoteId,
        smsOptIn: true,
        smsPhone: phoneNumber,
      };
      await apiCallPurchase(url, { post: body });
    } catch (error) {
      doNothing();
    }
  };
}

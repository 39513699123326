import React from 'react';
import { useSelector } from 'react-redux';

import { CUSTOMER_SERVICE_PHONE_NUMBER } from '../../constants';
import deskHelpIcon from '../../assets/purchase/questions.svg';
import './QuestionsInfo.css';

const QuestionsInfo = ({ isPurchase = false }) => {
  const store = useSelector((stores) => stores.quoting);
  const { partners: { data, isCostco } } = store;

  let title = 'Questions?';
  let supportPhoneNumber = `${CUSTOMER_SERVICE_PHONE_NUMBER}.`;
  let supportDesc = 'We\'re here to help. Give us a call at';
  let supportSchedule = 'Monday-Friday 7am-7pm CT Saturday 8am-6pm CT';

  if (isPurchase) {
    supportDesc = 'We’re here to help. Give us a call at (844) 738-3446.';
    supportPhoneNumber = '';
  }

  if (isCostco) {
    title = 'VIP Support';
    supportPhoneNumber = data.SupportPhoneNumber;
    supportDesc = data.SupportLabel;
    supportSchedule = data.SupportSchedule;
  }

  return (
    <div className={'Questions-sidebar-container Questions-innerent'
      + `${isPurchase ? ' Questions-purchase' : ' '}`}
    >
      <div className="Questions-sidebar-header">
        <img
          alt=""
          className="Questions-sidebar-help-desk-icon"
          src={deskHelpIcon}
        />

        <h3
          className={`${isPurchase ? 'Questions-sidebar-title-purchase'
            : 'Questions-sidebar-title '}`}
        >
          {title}
        </h3>
      </div>

      <div className="Question-sidebar-text-mobile">
        <p className="Questions-sidebar-questions-text">
          {supportDesc} {supportPhoneNumber}
        </p>

        <p className={'Questions-sidebar-questions-text '
          + 'Questions-sidebar-questions-text-subtitle'}
        >
          {supportSchedule}
        </p>
      </div>
    </div>
  );
};

export { QuestionsInfo };

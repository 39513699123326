import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FigoLottie from './FigoLottie';

import { ONE_INC_PAYMENT_CATEGORY, PAYMENT_TYPE } from '../../constants';
import { PAYMENT_METHOD_TYPE_CHANGE } from '../../actions/types';
import {
  paymentMethodChanged,
  validateDiamondUser,
} from '../../actions/quoting';
import {
  changeOneIncPaymentUpdate,
  createOneIncSession,
  removeOneIncSession,
  savePaymentMethod,
  savePaymentMethods,
  updatePaymentMethods,
} from '../../actions/oneInc';
import { sleep, useFSCLeads, useTrackingEvents } from '../../util';

import cardIcon from '../../assets/yourInfo/add-card-icon.svg';
import bankAccountIcon from '../../assets/bank-active.svg';
import creditCardIcon from '../../assets/bank-card.png';
import warningIcon from '../../assets/warning.svg';
import creditCardNavyIcon from '../../assets/bank-card-navy.svg';
import infoIcon from '../../assets/info.png';
import undoIcon from '../../assets/undo-navy.svg';
import spinner from '../../assets/spinner.json';
import './OneIncModal.css';
import {
  useTrackingPaymentMethodSelection,
  useTrackingPaymentMethodAddition,
} from '../../trackingEvents';
import { sendFSCPaymentLead } from '../../actions';

const OneIncModal = ({ onComplete = () => { }, styleUpdate = false }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('quoting');
  const store = useSelector(({ quoting }) => ({
    email: quoting.newCustomer.email,
    emailValiated: quoting.newCustomer.emailValidated,
    groupCode: quoting.data.groupCode,
    isCostco: quoting.partners.isCostco,
    oneInc: quoting.oneInc,
    parameters: quoting.parameters,
    partners: quoting.partners,
    paymentMethodType: quoting.paymentMethod.paymentMethodType,
    sessionInformation: quoting.sessionInformation,
    subId: quoting.subId,
  }), shallowEqual);
  const divRef = useRef(null);
  const updateButtonRef = useRef();
  const [loading, setLoading] = useState(false);
  const { nopCommerceUser } = store.sessionInformation;
  const { Address } = nopCommerceUser;
  const renderTwoPaymement = store.oneInc.isTwoDefaults
    && !store.oneInc.customerUpdated;
  const { trackPaymentMethodAddition } = useTrackingPaymentMethodAddition();
  const { trackPaymentMethodSelection } = useTrackingPaymentMethodSelection();

  const saveComplete = useCallback((_, data) => {
    setLoading(false);
    dispatch(changeOneIncPaymentUpdate(true));
    dispatch(savePaymentMethod(data));
    dispatch(removeOneIncSession());

    if (useTrackingEvents) {
      const isCreditCard =
        data.paymentCategory === ONE_INC_PAYMENT_CATEGORY.creditCard;

      trackPaymentMethodAddition({ isCreditCard });
    }

    if (useFSCLeads) {
      dispatch(sendFSCPaymentLead());
    }

    const handleComplete = async () => {
      if (onComplete) {
        // await to reducer update with card/bank data
        await sleep(300);

        onComplete();
      }
    };

    handleComplete();
  }, [dispatch, onComplete, trackPaymentMethodAddition]);
  const unLoad = useCallback(() => {
    setLoading(false);
    dispatch(removeOneIncSession());
    if (divRef.current) {
      divRef.current.focus();
    } else if (updateButtonRef.current) {
      updateButtonRef.current.focus();
    }
  }, [dispatch]);
  const onError = useCallback(() => {
    setLoading(false);
    dispatch(removeOneIncSession());
  }, [dispatch]);

  useLayoutEffect(() => {
    if (!divRef || !window.$) {
      return;
    }

    const initOneIncListener = async () => {
      // wait for jQuery script
      await sleep(300);

      if (!window.$) {
        return;
      }

      window.$('#portalOneContainer').off();

      window.$('#portalOneContainer').on('portalOne.unload', unLoad);
      window.$('#portalOneContainer')
        .on('portalOne.saveComplete', saveComplete);
      window.$('#portalOneContainer').on('portalOne.error', onError);
    };

    initOneIncListener();
  }, [
    dispatch,
    divRef,
    onError,
    saveComplete,
    trackPaymentMethodAddition,
    unLoad,
    updateButtonRef,
  ]);

  useEffect(() => {
    if (!store.oneInc.portalOneSessionKey || !divRef) {
      return;
    }

    if (!window.$) {
      setLoading(false);
      dispatch(removeOneIncSession());
      return;
    }

    const billingAddressStreet = Address.City && Address.State
      ? `${Address.AddressLine1} ${Address.AddressLine2}`
      + ` ${Address.City}, ${Address.State}`
      : '';

    const params = {
      billingAddressStreet,
      billingZip: Address.ZipCode,
      clientReferenceData1: nopCommerceUser.DiamonClientdId,
      confirmationDisplay: 'false',
      paymentCategory: 'UserSelect',
      policyHolderName: `${nopCommerceUser.FirstName}`
        + ` ${nopCommerceUser.LastName}`,
      sessionId: store.oneInc.portalOneSessionKey,
    };

    window.$('#portalOneContainer').portalOne();
    window.$('#portalOneContainer').data('portalOne').savePaymentMethod(params);
  }, [store.oneInc.portalOneSessionKey, Address, nopCommerceUser, dispatch]);

  useEffect(() => () => {
    if (window.$ && typeof window.$ === 'function') {
      window.$('#portalOneContainer').off();
    }
  });

  function onAddPaymentMethod() {
    if (!store.emailValiated) {
      dispatch(validateDiamondUser({ email: store.email }));
      return;
    }

    setLoading(true);
    dispatch(createOneIncSession());
  }

  const onDefaultPaymentMethodUpdated = (data) => () => {
    dispatch(updatePaymentMethods(data));
    const paymentMethodSelected = data === ONE_INC_PAYMENT_CATEGORY.bankAccount
      ? PAYMENT_TYPE.bank
      : PAYMENT_TYPE.creditCard;

    if (store.paymentMethodType.value !== paymentMethodSelected.value) {
      dispatch(paymentMethodChanged({
        type: PAYMENT_METHOD_TYPE_CHANGE,
        value: paymentMethodSelected,
      }));

      if (useTrackingEvents) {
        trackPaymentMethodSelection({
          codeMask: store.parameters.groupCodeDscr,
          email: store.parameters.email,
          isCreditCard: data === ONE_INC_PAYMENT_CATEGORY.creditCard,
          promoCode: store.groupCode || '',
          subId: store.subId.Value,
        });
        if (useFSCLeads) {
          dispatch(sendFSCPaymentLead());
        }
      }
    }
  };

  function renderUpdateButton({ className = '', text, centerButtom = true }) {
    if (loading) {
      return centerButtom
        ? (
          <div className="One-inc-button-loading">
            <FigoLottie
              animationData={spinner}
              height={30}
              width={100}
            />
          </div>
        ) : (
          <FigoLottie
            animationData={spinner}
            height={30}
            width={100}
          />
        );
    }

    return (
      <button
        ref={updateButtonRef}
        className={`One-modal-details-button ${className}`}
        onClick={onAddPaymentMethod}
        type="button"
      >
        {text}
      </button>
    );
  }

  function renderOneItemDetails({
    icon,
    lastFourDigits,
    subTitle,
    title = '',
  }) {
    return (
      <div className="One-modal-details One-modal-detail-update-style">
        <div className="One-modal-details-left">
          <img alt="" className="One-modal-details-image" src={icon} />

          <div aria-live={store.oneInc.customerUpdated ? 'polite' : 'off'}>
            <strong>{title.toUpperCase()}</strong>

            <span className="One-modal-last-digits">
              {lastFourDigits}
            </span>

            <span>{subTitle}</span>
          </div>
        </div>

        {renderTwoPaymement
          ? null
          : renderUpdateButton({
            className: 'One-modal-details-button-update ',
            text: t('payment.edit'),
          })}
      </div>
    );
  }

  function renderCreditCardDeatils() {
    if (!store.oneInc.tokenId) {
      return null;
    }

    const { creditCard } = store.oneInc;

    const subtitle = creditCard.cardExpirationMonth
      ? `Exp. ${creditCard.cardExpirationMonth}/`
      + `${String(creditCard.cardExpirationYear).substring(2)}`
      : '';
    const details = {
      className: 'One-modal-details-image',
      icon: styleUpdate ? creditCardNavyIcon : creditCardIcon,
      lastFourDigits: `Card ending in ${creditCard.lastFourDigits}`,
      subTitle: subtitle,
      title: creditCard.cardType || 'Credit card',
    };

    return renderOneItemDetails(details);
  }

  function renderBankAccountDetails() {
    if (!store.oneInc.tokenId) {
      return null;
    }

    const { bankAccount } = store.oneInc;

    const subtitle = bankAccount.accountType;
    const details = {
      className: 'One-modal-details-image',
      icon: bankAccountIcon,
      lastFourDigits: `Account ending in ${bankAccount.lastFourDigits}`,
      subTitle: subtitle,
      title: 'BANK ACCOUNT',
    };

    return renderOneItemDetails(details);
  }

  function renderCheckBox(checked, isCreditCard, methodData) {
    return (
      <div className="One-inc-radio-button-container">
        <label htmlFor={isCreditCard ? 'rb-credit-card' : 'rb-bank'}>
          <input
            checked={checked}
            id={isCreditCard ? 'rb-credit-card' : 'rb-bank'}
            name="paymentmethod"
            onChange={onDefaultPaymentMethodUpdated(methodData)}
            type="radio"
            value={isCreditCard
              ? PAYMENT_TYPE.creditCard.value
              : PAYMENT_TYPE.bank.value}
          />

          {isCreditCard
            ? renderCreditCardDeatils() : renderBankAccountDetails()}
        </label>
      </div>
    );
  }

  function renderTwoCardDetails() {
    const isCreditCard =
      store.oneInc.paymentCategory === ONE_INC_PAYMENT_CATEGORY.creditCard;
    const { bankAccount, creditCard } = ONE_INC_PAYMENT_CATEGORY;

    if (renderTwoPaymement) {
      return (
        <fieldset>
          <div className="One-modal-button-select">
            <div className="Flex">
              {renderCheckBox(!isCreditCard, false, bankAccount)}
            </div>
          </div>

          <div className="One-modal-button-select">
            <div className="Flex">
              {renderCheckBox(isCreditCard, true, creditCard)}
            </div>
          </div>

          {renderUpdateButton({
            centerButtom: false,
            text: 'Use a different payment method?',
          })}
        </fieldset>
      );
    }

    return isCreditCard
      ? renderCreditCardDeatils() : renderBankAccountDetails();
  }

  function onUndoChage() {
    const { defaultsPaymentMethod, isTwoDefaults } = store.oneInc;

    const isCreditCardDefault = defaultsPaymentMethod.defaultValue
      === ONE_INC_PAYMENT_CATEGORY.creditCard;
    const data = isCreditCardDefault
      ? defaultsPaymentMethod.creditCard : defaultsPaymentMethod.bankAccount;

    dispatch(changeOneIncPaymentUpdate(false));

    if (isTwoDefaults) {
      dispatch(savePaymentMethods({
        bankAccount: defaultsPaymentMethod.bankAccount,
        creditCard: defaultsPaymentMethod.creditCard,
        paymentCategory: ONE_INC_PAYMENT_CATEGORY.bankAccount,
      }));
    } else {
      dispatch(savePaymentMethod(data));
    }
  }

  function renderWarning() {
    const {
      paymentCategory,
      customerUpdated,
      petPaymentMethods,
    } = store.oneInc;

    if (!customerUpdated) {
      return null;
    }

    const pets = petPaymentMethods
      .filter((item) => item.Value === paymentCategory)
      .map((item) => item.Key);
    let petsNames = '';

    if (pets.length === 0) {
      return null;
    }

    if (pets.length === 1) {
      petsNames = pets;
    } else if (pets.length === 2) {
      petsNames = pets.join(' and ');
    } else {
      const firtsPets = pets
        .filter((_, index) => index < pets.length - 1).join(', ');
      const endPet = pets[pets.length - 1];

      petsNames = `${firtsPets} and ${endPet}`;
    }

    const isCreditCard =
      paymentCategory === ONE_INC_PAYMENT_CATEGORY.creditCard;
    const paymentType = isCreditCard ? 'card' : 'bank account';

    return (
      <div className="One-inc-modal-warning-container">
        <img alt="" src={infoIcon} />

        <div className="One-inc-modal-warning-right">
          <p>
            Proceeding with this new {paymentType} will also update
            the policies for
            <span> {petsNames}</span>.
          </p>

          {!styleUpdate && (
            <button onClick={onUndoChage} type="button">
              <img alt="" src={undoIcon} />

              <span>Undo Change</span>
            </button>
          )}
        </div>
      </div>
    );
  }

  const renderDisclaimer = () => (
    <span
      className={'One-inc-section-subtitle'
        + `${styleUpdate ? ' One-inc-section-subtitle-center' : ''}`}
    >
      For assistance with adding a Payment Method call
      VIP Support at <span>{store.partners.data?.SupportPhoneNumber}</span>
    </span>
  );

  const renderButtonText = () => (
    <>
      {styleUpdate
        ? 'Update Payment Method'
        : <><img alt="card" src={cardIcon} /> Add Payment Method</>}
    </>
  );

  const renderChargeWarning = useCallback(() => store.oneInc.tokenId && (
    <div className="One-inc-section-warning">
      <img alt="" src={warningIcon} />

      {t('payment.notChargedYet')}
    </div>
  ), [store.oneInc.tokenId, t]);

  return (
    <>
      {store.isCostco && renderDisclaimer()}

      {(!store.oneInc.tokenId && nopCommerceUser.DiamonClientdId)
        ? (
          <>
            <div
              className={styleUpdate
                ? 'Purchase-result-fail-button-container'
                : 'One-inc-add-payment-button-container'}
            >
              <button
                ref={divRef}
                aria-label="Add payment method"
                className={styleUpdate
                  ? 'Purchase-result-fail-button'
                  : 'btn One-inc-add-payment-button'}
                onClick={onAddPaymentMethod}
                type="button"
              >
                {loading
                  ? <FigoLottie height={30} width={30} />
                  : renderButtonText()}
              </button>
            </div>
          </>
        ) : null}

      {renderTwoCardDetails()}

      {renderWarning()}

      {renderChargeWarning()}
    </>
  );
};

export { OneIncModal };

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import ModalError from '../../common/ModalError';
import './PurchaseError.css';
import PurchasePage from './PurchasePage';
import { resetPurchaseData } from '../../../actions';
import { CUSTOMER_SERVICE_PHONE_NUMBER } from '../../../constants';
import { closeWindow } from '../../../util';
import { URL_YOUR_INFO } from '../../../routes';

const PurchaseError = () => {
  const store = useSelector(({ quoting }) => quoting);
  const history = useHistory();
  const dispatch = useDispatch();
  const { partners: { data, isCostco }, inApp, utmParams } = store;

  const error = `Sorry, we're unable to provide a quote at this time. 
  Please contact Customer Service at ${isCostco
    ? data.SupportPhoneNumber.replace('.', '')
    : CUSTOMER_SERVICE_PHONE_NUMBER} to purchase a policy.`;

  useEffect(() => {
    dispatch(resetPurchaseData());
  }, [dispatch]);

  function handleErrorGoBack() {
    if (inApp) {
      closeWindow();
    } else {
      history.push({
        pathname: URL_YOUR_INFO,
        search: queryString.stringify(utmParams),
      });
    }
  }

  return (
    <PurchasePage>
      <div className="Purchase-error-container">
        <ModalError
          buttonText="Go Back"
          message={error}
          onButtonClick={handleErrorGoBack}
        />
      </div>
    </PurchasePage>
  );
};

export default PurchaseError;

import React, { useCallback, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { PartnerDiscountLabel }
from '../components/quoting/PartnerDiscountLabel';
import { GoodDogDiscountLabel }
from '../components/quoting/GoodDogDiscountLabel';
import { saveNewPetPickupDate, updateRateQuote } from '../actions';
import { validatePickupDate, validatePickupDate7Days } from './partners.util';
import { useCostcoTN, useGoodDogTN } from '../util';
import {
  GenericPartnerDiscountLabel,
} from '../components/quoting/GenericPartnerDiscountLabel';

export const usePartnersData = () => {
  const store = useSelector(({ quoting }) => quoting, shallowEqual);
  const dispatch = useDispatch();
  const {
    data,
    isCostco,
    isGoodDog,
    isUSAA,
    loading,
    partnerId,
    petPickupDate,
  } = store?.partners;

  const isPickupDateValid = React
    .useMemo(() => validatePickupDate(petPickupDate), [petPickupDate]);

  const isPickupDateValid7Days = React
    .useMemo(() => validatePickupDate7Days(petPickupDate), [petPickupDate]);

  const useTennesseeValidations = useMemo(() => {
    if (isCostco) {
      return useCostcoTN;
    }

    if (isGoodDog) {
      return useGoodDogTN;
    }

    return false;
  }, [isCostco, isGoodDog]);

  const renderCardPlanHeader = useCallback(() => {
    if (isCostco) {
      return (
        <div className="Card-plan-partner-label-container">
          <PartnerDiscountLabel discountLabel={data.DiscountLabel} />
        </div>
      );
    }

    if (isGoodDog) {
      return (
        <div className="Card-plan-partner-label-container">
          <GoodDogDiscountLabel logo={data.Logo} />
        </div>
      );
    }

    if (data?.Logo || data?.DiscountLabel) {
      return (
        <div className="Card-plan-partner-label-container">
          <GenericPartnerDiscountLabel
            label={data?.DiscountLabel}
            logo={data.Logo}
          />
        </div>
      );
    }

    return null;
  }, [data.DiscountLabel, data.Logo, isCostco, isGoodDog]);

  const onPetPickupDateChange = useCallback(({ target }) => {
    const { value } = target;

    validatePickupDate(value);

    dispatch(saveNewPetPickupDate(value));
  }, [dispatch]);

  const updateRateByPickupDate = useCallback(() => {
    const petQuoteList = store.data.petQuoteResponseList;
    const petSelected = petQuoteList.find((pet) => (
      pet.petQuoteId === store.petQuoteSelected));

    const {
      parameters,
      guidData,
      sessionInformation: { nopCommerceUser, userEmail },
    } = store;

    dispatch(updateRateQuote({
      diamondClientId: nopCommerceUser.DiamonClientdId,
      effectiveDateCustom: '',
      eMail: userEmail || parameters.email,
      employerName: '',
      guidPayload: guidData,
      modifiers: petSelected.modifiers,
      nopCommerceClientId: nopCommerceUser.CustomerNopCommerceId,
      parameters,
      petQuoteList,
      promoCode: store.data.groupCode,
      quoteId: petSelected.petQuoteId,
      showLoading: true,
    }));
  }, [dispatch, store]);

  return {
    data,
    goodDogData: {
      isPickupDateValid,
      isPickupDateValid7Days,
      onPetPickupDateChange,
      petPickupDate,
      updateRateByPickupDate,
    },
    isCostco,
    isGoodDog,
    isPartner: !!partnerId,
    isUSAA,
    loading,
    renderCardPlanHeader,
    useTennesseeValidations,
  };
};

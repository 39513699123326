import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import TagPetCover from '../../common/TagPetCover';
import './PaymentBreakdown.css';
import {
  BILLING_CYCLE_TYPE,
  EXECUTIVE_MEMBERSHIP_TYPE_NAME,
  POWERUP_TYPE,
} from '../../../constants';
import { currencyFormat } from '../../../util';
import TooltipContainer from '../../common/TooltipContainer';
import { PartnerDiscountCallout } from './PartnerDiscountCallout';

const PaymentBreakdown = () => {
  const [applyDiscount, setApplyDiscount] = useState(false);
  const store = useSelector(({ quoting }) => quoting, shallowEqual);
  const {
    data,
    isMonthlyPlan,
    partners: {
      costcoMembership: {
        isFeeWaived,
        executeMemberBenefitDisplay,
        executeMemberBenefitWaivedAmount,
        feeWaivedMessageContent,
        feeWaivedMessageTitle,
      },
      data: {
        MembershipValidation,
      },
      isCostco,
    },
    paymentMethod: { paymentMethodType },
    petTags,
    quoteSelectedValues,
    rateSummary,
  } = store;

  const { InsuranceProductFee } = data.insuranceProduct;

  const executiveMembership = MembershipValidation
    ? MembershipValidation.MembershipTypes?.find(
      (membership) => membership.Name === EXECUTIVE_MEMBERSHIP_TYPE_NAME,
    ) : null;

  const getPowerUpsByQuoteId = useCallback((quoteId) => {
    const quoteModifiers = data.petQuoteResponseList
      .find((item) => item.petQuoteId === quoteId)?.modifiers || [];
    const { insuranceProduct: { InsuranceModifiers } } = data;
    const powerUpsFilter = InsuranceModifiers
      .filter((modifier) => modifier.InsuranceModifierTypeId === POWERUP_TYPE
        && modifier.IsVisible)
      .map((powerUp) => ({
        id: powerUp.Id,
        isIncluded: quoteModifiers
          .find((item) => item.id === powerUp.Id)?.isSelected || false,
        name: powerUp.AppText,
      }));

    return powerUpsFilter;
  }, [data]);

  const BreakDownPowerUpRow = ({ isIncluded, medium, name }) => (
    <li className="Row Payment-breakdown-details-row">
      <span>{name}</span>

      <span className={`${medium ? 'Payment-breakdown-summary' : ''}`}>
        {isIncluded ? 'Included' : 'Not Included'}
      </span>
    </li>
  );

  const BreakDownBenefitRow = ({ bold, medium, name, value }) => (
    <li className={'Row Payment-breakdown-details-row-total'
      + ` ${bold ? 'IvyEpic-bold-14' : ''}`}
    >
      <span>{name}</span>

      <span
        className={`${medium
          ? 'IvyEpic-Regular-14 Payment-breakdown-summary' : ''}`}
      >
        {value}
      </span>
    </li>
  );

  function renderBreakDownList(quoteId) {
    const powerUps = getPowerUpsByQuoteId(quoteId);

    return (
      <div key={String(quoteId)}>
        <ul>
          {powerUps.map((powerUp) => (
            <BreakDownPowerUpRow
              key={String(powerUp.id)}
              isIncluded={powerUp.isIncluded}
              name={powerUp.name}
            />
          ))}
        </ul>
      </div>
    );
  }

  function renderTaxesList(quoteId) {
    const quote = rateSummary.PetQuotes
      .find((item) => item.PetQuoteId === quoteId) || {};
    const taxes = isMonthlyPlan ? quote.MonthlyTaxes : quote.AnnualTaxes;

    if (!taxes) {
      return null;
    }

    return (
      taxes.map((item) => (
        <BreakDownBenefitRow
          key={String(`${item.Description}${item.Amount}`)}
          bold
          name={item.Description}
          value={`$${currencyFormat({ value: item.Amount })}`}
        />
      ))
    );
  }

  function getTaxes(quoteId) {
    const quote = rateSummary.PetQuotes
      .find((item) => item.PetQuoteId === quoteId) || {};
    const taxes = isMonthlyPlan ? quote.MonthlyTaxes : quote.AnnualTaxes;

    if (!taxes) {
      return 0;
    }

    return taxes.reduce((prev, next) => (prev + next.Amount), 0);
  }

  function getOneTimeFee() {
    const { oneTime } = BILLING_CYCLE_TYPE;

    const fee = InsuranceProductFee
      .find((item) => item.InsuranceFee.BillingCycleType === oneTime);

    return fee;
  }

  function getInstallmentFee() {
    const { annualy, monthly } = BILLING_CYCLE_TYPE;
    const billingCycle = isMonthlyPlan ? monthly : annualy;
    const fee = InsuranceProductFee
      .find((item) => item.InsuranceFee.BillingCycleType === billingCycle
        && item.InsuranceFee.PaymentType === paymentMethodType.value);

    return fee;
  }

  const oneTimeFee = getOneTimeFee();
  const installmentFee = getInstallmentFee();

  useEffect(() => {
    const apply = isCostco && oneTimeFee && isFeeWaived;

    setApplyDiscount(apply);
  }, [isCostco, isFeeWaived, oneTimeFee]);

  function getTotal(quote) {
    const { annualAmountPlan, monthlyAmountPlan } = quote;
    const taxesTotal = getTaxes(quote.quoteId);
    let oneTimeAmount = 0;
    let installmentAmount = 0;

    if (oneTimeFee && !isFeeWaived) {
      oneTimeAmount = oneTimeFee.InsuranceFee.Amount;
    }

    if (installmentFee) {
      installmentAmount = installmentFee.InsuranceFee.Amount;
    }

    const baseTotal = isMonthlyPlan ? monthlyAmountPlan : annualAmountPlan;

    return baseTotal + installmentAmount + oneTimeAmount + taxesTotal;
  }

  return (
    <div className="Payment-breakdown-container">
      {data.petQuoteResponseList.map((quoteRate) => {
        const quote = quoteSelectedValues
          .find((pet) => pet.quoteId === quoteRate.petQuoteId);
        const petTag =
          petTags.find((item) => item.petQuoteId === quote.quoteId) || {};

        const reimbursementAriaLabel =
          quote.reimbursementPercentage.replace('%', '');
        const deductibleAriaLabel = `Deductible ${quote.deductibleQuantity}`;
        const benefitsAriaLabel = 'Annual Benefit '
          + `${quote.selectedPlanAmount.replace('K', ' thousand')}`;

        return (
          <div
            key={String(quoteRate.petQuoteId)}
            className="Column Payment-breakdown-pet-row-container"
          >
            <div className="Row Full-width-mobile">
              <div className="Payment-breakdown-pet-tag-container">
                <TagPetCover
                  className="Payment-breakdown-pet-tag"
                  petType={petTag.petType}
                />
              </div>

              <div className="Payment-breakdown-plan-summary-container">
                <div className="Payment-breakdown-pet-summary-container">
                  <h2
                    aria-label={`Payment Summary for ${petTag.petName}`}
                    className="Payment-breakdown-pet-name"
                  >
                    {petTag.petName}
                  </h2>

                  <div
                    className="IvyEpic-Regular-14 Payment-breakdown-summary"
                  >
                    <TooltipContainer
                      arrowPosition="start"
                      description="Annual Benefit"
                    >
                      <span
                        aria-label={benefitsAriaLabel}
                        className="Payment-breakdown-summary-first-child"
                      >
                        {quote.selectedPlanAmount.toUpperCase()}
                      </span>
                    </TooltipContainer>

                    <TooltipContainer
                      arrowPosition="start"
                      description="Reimbursement"
                    >
                      <span
                        aria-label={'Reimbursement '
                          + `${reimbursementAriaLabel} per cent`}
                        className="Payment-breakdown-summary-reimbursement"
                      >
                        {quote.reimbursementPercentage}
                      </span>
                    </TooltipContainer>

                    <TooltipContainer
                      arrowPosition="start"
                      description="Annual Deductible"
                    >
                      <span
                        aria-label={deductibleAriaLabel}
                        className="Payment-breakdown-summary-last-child"
                      >
                        {quote.deductibleQuantity}
                      </span>
                    </TooltipContainer>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={'Row Payment-breakdown-details-container '
                + 'Text-primary IvyEpic-Regular-14'}
            >
              <div className="Column Payment-breakdown-power-ups-container">
                {renderBreakDownList(quote.quoteId)}
              </div>

              <div className="Column  Payment-breakdown-benefits-container">
                <ul>
                  <BreakDownBenefitRow
                    name={isMonthlyPlan
                      ? 'Monthly Premium' : 'Annual Premium'}
                    value={isMonthlyPlan
                      ? `$${currencyFormat({ value: quote.monthlyAmountPlan })}`
                      : `$${currencyFormat({ value: quote.annualAmountPlan })}`}
                  />

                  {installmentFee && (
                    <BreakDownBenefitRow
                      name={installmentFee.InsuranceFee.DisplayName}
                      value={`$${currencyFormat({
                        value: installmentFee.InsuranceFee.Amount,
                      })}`}
                    />
                  )}

                  {oneTimeFee && (
                    <BreakDownBenefitRow
                      name={oneTimeFee.InsuranceFee.DisplayName}
                      value={`$${currencyFormat({
                        value: oneTimeFee.InsuranceFee.Amount,
                      })}`}
                    />
                  )}

                  {applyDiscount && (
                    <BreakDownBenefitRow
                      name={executeMemberBenefitDisplay}
                      value={`-$${currencyFormat({
                        value: executeMemberBenefitWaivedAmount,
                      })}`}
                    />
                  )}

                  {renderTaxesList(quote.quoteId)}

                  <BreakDownBenefitRow
                    bold
                    name={`${petTag.petName}’s Total`}
                    value={`$${currencyFormat({ value: getTotal(quote) })}`}
                  />
                </ul>
              </div>
            </div>
            {applyDiscount && (
              <div className="Row Payment-breakdown-discount-callout-container">
                <PartnerDiscountCallout
                  membershipImage={executiveMembership?.Image}
                  messageContent={feeWaivedMessageContent}
                  messageTitle={feeWaivedMessageTitle}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default PaymentBreakdown;

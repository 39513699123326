export const COSTCO_PARTNER_IDS = [
  '4BC8B4C4-E147-41F8-8E96-B2E693CC2AD9',
  'E22B67D6-6228-45DE-90D7-26D4470E7CDE',
];

export const COSTCO_PARTNER_ID = COSTCO_PARTNER_IDS[0];

export const COSTCO_CX_PARTNER_ID = COSTCO_PARTNER_IDS[1];

export const GOOD_DOG_PARTNER_ID = '21229794-9CAA-4930-ABA6-B543A2C0CCDB';

export const USAA_PARTNER_ID = 'F85EA715-03FE-4149-9F11-5A8233C2504E';

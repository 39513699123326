import { apiCallMGMT } from '../util';
import {
  COSTCO_MEMBERSHIP_EDIT_CLICKED,
  COSTCO_MEMBERSHIP_ERROR_MESSAGE_SET,
  COSTCO_MEMBERSHIP_NUMBER_CHANGED,
  COSTCO_MEMBERSHIP_VALIDATED,
  COSTCO_MEMBERSHIP_VALIDATION_FAILED,
  COSTCO_MEMBERSHIP_VALIDATION_STARTED,
  LOADING_QUOTE_DATA_FINISH,
  NOTIFY_ME_EMAIL_VALUES_SAVED,
  PROCEED_WITHOUT_PARTNER_CHANGED,
} from './types';
import { rateSummary, updateRateQuote } from './quoting';
import { getStateAbbreviation } from '../utils/zipcode';

export function changeCostcoMembershipNumber(membershipNumber) {
  return {
    membershipNumber,
    type: COSTCO_MEMBERSHIP_NUMBER_CHANGED,
  };
}

const EXEC_MEMBERSHIP = 'EXEC';
const PAYMENT_SUMMARY = 'paymentSummary';
const MESSAGE_FIELD_NAME = 'MessageMembershipValidated';
const EXECUTIVE_MESSAGE_FIELD_NAME = 'MessageMembershipValidatedExecutive';

export function validateCostcoMembership({
  customerZipCode,
  membershipNumber,
  rateSummaryBody,
  waivableFee,
}) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: COSTCO_MEMBERSHIP_VALIDATION_STARTED,
      });

      const url = `api/costco/validatemember/${membershipNumber}`;

      const request = await apiCallMGMT(url);
      const costcoResponse = await request.json();

      if (costcoResponse.valid) {
        const {
          quoting: {
            partners: { data },
          },
        } = getState();
        const { CustomFields } = data;
        const stateAbbr = getStateAbbreviation(String(customerZipCode));
        // There are states where we are not allowed to give enrollment
        // fee waiver to executive members.
        const STATES_NO_FEE_WAIVED = ['MI', 'RI'];
        const stateIsNoFeeWaived = STATES_NO_FEE_WAIVED.includes(stateAbbr);
        const isExecutive = costcoResponse.membershipTier === EXEC_MEMBERSHIP;
        let messageName = MESSAGE_FIELD_NAME;
        const isFeeWaived =
          waivableFee > 0 && isExecutive && !stateIsNoFeeWaived;
        const fields = {};

        if (isFeeWaived) {
          const customFields = CustomFields
            .filter((field) => field.Section === PAYMENT_SUMMARY);
          customFields.forEach((field) => {
            fields[field.Name] = field.Content;
          });
          messageName = EXECUTIVE_MESSAGE_FIELD_NAME;
        }

        const messageField = CustomFields
          .find((field) => field.Name === messageName);
        const message = messageField ? messageField.Content : '';

        const membershipTypeName = isExecutive
          ? costcoResponse.membershipTierDescription
          : costcoResponse.membershipTypeDescription.replace(/\s/, '');

        dispatch({
          ...fields,
          customerZipCode,
          isFeeWaived,
          membershipTypeName,
          message,
          type: COSTCO_MEMBERSHIP_VALIDATED,
          waivableFee,
        });

        dispatch(rateSummary({
          ...rateSummaryBody,
          Partner: {
            ...rateSummaryBody.Partner,
            IsFeeWaived: isFeeWaived,
            MembershipType: membershipTypeName,
          },
        }));
      } else {
        dispatch({
          error: costcoResponse.errorMessage,
          type: COSTCO_MEMBERSHIP_VALIDATION_FAILED,
        });
      }
    } catch (_) {
      dispatch({
        error: 'Could not validate member ID, please try again',
        type: COSTCO_MEMBERSHIP_VALIDATION_FAILED,
      });
    }
  };
}

export function costcoMembershipEditClicked() {
  return {
    type: COSTCO_MEMBERSHIP_EDIT_CLICKED,
  };
}

export function costcoMembershipSetErrorMessage(errorMessage) {
  return {
    error: errorMessage,
    type: COSTCO_MEMBERSHIP_ERROR_MESSAGE_SET,
  };
}

export function changeProceedWithoutCostcoMembership({
  diamondClientId,
  effectiveDateCustom,
  eMail,
  employerName,
  isProceedWithoutPartner,
  nopCommerceClientId,
  parameters,
  petQuoteList,
  quoteId,
}) {
  return async (dispatch) => {
    await dispatch({
      isCostco: !isProceedWithoutPartner,
      type: PROCEED_WITHOUT_PARTNER_CHANGED,
    });
    await dispatch(updateRateQuote({
      diamondClientId,
      effectiveDateCustom,
      eMail,
      employerName,
      nopCommerceClientId,
      parameters,
      petQuoteList,
      quoteId,
      showLoading: true,
    }));
  };
}

export function saveNotifyMeValuesEmail({
  email,
  petAge,
  petBreed,
  petBreedId,
  petName,
  petSex,
  zipcode,
}) {
  return {
    type: NOTIFY_ME_EMAIL_VALUES_SAVED,
    valuesEmail: {
      email,
      petAge,
      petBreed,
      petBreedId,
      petName,
      petSex,
      zipcode,
    },
  };
}

export function hideLoading() {
  return { type: LOADING_QUOTE_DATA_FINISH };
}

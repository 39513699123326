import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { closeErrorModal } from '../../actions';
import { PROCEED_WITHOUT_PARTNER_CHANGED } from '../../actions/types';
import ModalContainer from './ModalContainer';
import ModalError from './ModalError';
import { LANDING_URL, URL_COSTCO } from '../../routes';
import { useB2CInfo } from '../../hooks';

const GenericErrorModalContainer = () => {
  const dispatch = useDispatch();
  const store = useSelector(({ quoting }) => quoting);
  const {
    message,
    parameters,
    partners: { isCostco },
    redirectToLanding,
    utmParams,
  } = store;
  const history = useHistory();
  const location = useLocation();
  const { logoutB2C } = useB2CInfo();

  function goToLanding() {
    if (location.pathname.toLowerCase() !== LANDING_URL) {
      let landingUrl = LANDING_URL;
      const stringifiedParams = queryString.stringify(utmParams);

      if (isCostco) {
        landingUrl = `${URL_COSTCO}${
          stringifiedParams ? `?${stringifiedParams}` : ''
        }`;
      } else {
        if (parameters.groupCode) {
          const str = `?groupCode=${parameters.groupCode}&${stringifiedParams}`;
          landingUrl += str;
        } else {
          landingUrl += `?${stringifiedParams}`;
        }

        dispatch({
          isCostco: false,
          type: PROCEED_WITHOUT_PARTNER_CHANGED,
        });
      }

      history.push(landingUrl, {
        focus: true,
      });
    }
  }

  function handleErrorModalOutClick() {
    dispatch(closeErrorModal());
    goToLanding();
    logoutB2C({ logoutUrl: LANDING_URL });
  }

  useEffect(() => {
    if (redirectToLanding && Object.keys(parameters).length) {
      goToLanding();
    }

    // eslint-disable-next-line
  }, [redirectToLanding]);

  return (
    <ModalContainer
      cancellable
      handleOutClick={handleErrorModalOutClick}
      idTitle="genericError"
      show={message}
    >
      <ModalError
        buttonText="Got It"
        onButtonClick={handleErrorModalOutClick}
      />
    </ModalContainer>
  );
};

export default GenericErrorModalContainer;

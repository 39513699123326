import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import moment from 'moment';

import { currencyFormat } from '../../../util';
import { BILLING_CYCLE_TYPE } from '../../../constants';
import calendarIcon from '../../../assets/yourInfo/calendar-icon-deep-blue.svg';

const YourFirstPayment = () => {
  const store = useSelector(({ quoting }) => quoting, shallowEqual);
  const {
    data,
    isMonthlyPlan,
    paymentMethod: { paymentMethodType },
    rateSummary: { QuoteSummaries },
  } = store;
  const { effectiveDate } = data;
  const day = moment(effectiveDate).format('D');
  const ordinalDay = moment(effectiveDate).format('Do');

  function getTotal() {
    const billingCycle = isMonthlyPlan
      ? BILLING_CYCLE_TYPE.monthly : BILLING_CYCLE_TYPE.annualy;
    const paymentType = paymentMethodType.value;
    const summary = QuoteSummaries
      .find((item) => item.BillingCycle === billingCycle
        && item.PaymentType === paymentType)
      || { MonthlyPremiumTotal: 0, TodaysTotal: 0 };

    return {
      monthlyTotal: currencyFormat({ value: summary.MonthlyPremiumTotal }),
      todaysTotal: currencyFormat({ value: summary.TodaysTotal }),
    };
  }

  function getTotalText() {
    if (isMonthlyPlan) {
      return (
        <>
          {'Your account will be charged '}

          <div className="">
            {`$${getTotal().monthlyTotal} 
      on the `}

            <span className="Your-info-each-month">
              {`${ordinalDay} of each month.`}
            </span>
          </div>
        </>
      );
    }
    return 'Your policy will renew one year from the effective date.';
  }

  function getCalendarClass() {
    if (!isMonthlyPlan) {
      return 'Your-info-calendar-three';
    }

    return day > 9
      ? 'Your-info-calendar-two' : 'Your-info-calendar-one';
  }

  function getDaysCalendar() {
    return isMonthlyPlan ? day : 365;
  }

  return (
    <>
      <div className="Your-info-card-div">
        <div className="Your-info-card">
          <div className="Your-info-flex">
            <div aria-hidden="true" className="Your-info-calendar-container">
              <img alt="" src={calendarIcon} />

              <span className={getCalendarClass()}>
                {getDaysCalendar()}
              </span>
            </div>

            <p>
              {getTotalText()}
            </p>
          </div>

          <div className="Row Your-info-total-container">
            <span>Today&apos;s total</span>

            <span className="Your-info-total">
              ${getTotal().todaysTotal}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export { YourFirstPayment };

import React from 'react';

import { OneIncModal } from '../../common/OneIncModal';

import ill9 from '../../../assets/ills/ill-9.svg';

import './PaymentMethod.css';

const PaymentMethod = ({
  containerRef = null,
  showError = false,
}) => (
  <div
    ref={containerRef}
    className="Payment-method-container"
    id="paymentMethod"
  >
    <h3 className="Payment-method-title">Payment Method</h3>

    <OneIncModal />

    <img
      alt=""
      aria-hidden
      className="Ill-image Your-info-image-4 No-mobile No-tablet"
      src={ill9}
    />

    {showError ? (
      <span className="Payment-method-error-label">
        Payment Method is required
      </span>
    ) : null}
  </div>
);

export { PaymentMethod };

import React, { useMemo } from 'react';
import { PetQuoteDropDown } from '../../mobile/petQuotingDropDown';
import CardPlanMainHeaderCompany from '../CardPlanMainHeaderCompany';
import { PartnerDiscountLabel } from '../PartnerDiscountLabel';
import './css/QuotingHeader.css';
import { usePartnersData } from '../../../partners/usePartnersData';
import { GoodDogDiscountLabel } from '../GoodDogDiscountLabel';
import { GenericPartnerDiscountLabel } from '../GenericPartnerDiscountLabel';

const StickyHeader = ({
  discountLabel = '',
  isSecondStep = false,
  petQuoteResponseList = [],
}) => {
  const petQuoteList = useMemo(() => petQuoteResponseList,
    [petQuoteResponseList]);

  const { data, isCostco, isGoodDog, isPartner } = usePartnersData();

  const renderHeader = () => {
    if (isCostco) {
      return <PartnerDiscountLabel discountLabel={discountLabel} />;
    }

    if (isGoodDog) {
      return <GoodDogDiscountLabel logo={data?.Logo} />;
    }

    if (data?.Logo || data?.DiscountLabel) {
      return (
        <GenericPartnerDiscountLabel
          label={data?.DiscountLabel}
          logo={data?.Logo}
        />
      );
    }

    return null;
  };

  return (
    <>
      <PetQuoteDropDown
        classContainer="Quoting-header-dropdown"
        customClass="Quoting-item-container"
        hideAddPet={isSecondStep}
        petQuoteList={petQuoteList}
      />

      <div className={'Quoting-header-top-card-container '
        + 'Quoting-header-no-table-big'}
      >
        {isPartner
          ? renderHeader()
          : <CardPlanMainHeaderCompany isHeader isPartner={false} />}
      </div>
    </>
  );
};

export { StickyHeader };

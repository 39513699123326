import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIsAuthenticated } from '@azure/msal-react';
import { useLocation } from 'react-router-dom';

import {
  extoleQuoteUUIDSave,
  setCurrentQuoteStep,
  validateCustomerExistsByEmail,
  validateDiamondUser,
} from '../../actions';
import {
  isPaycheck,
  useCustomEffectiveDate,
  useCustomPlanPills,
  useEnrollmentflag,
  useROPC,
} from '../../util';
import { AFTER_RATE_TAG, QuotingSteps } from '../../constants';
import {
  useB2CInfo,
  useCallbackAfterRate,
  useExtole,
  useRateSummary,
  useResponsive,
} from '../../hooks';

import { EffectiveDateCustom } from '../common/EffectiveDateCustom';
import { PowerUpSection } from './powerups/PowerUpSection';
import DiscountApplied from './yourInfo/DiscountApplied';
import { MyPetsContainer } from './myPets/MyPetsContainer';
import CardPlanMainHeaderCompany from './CardPlanMainHeaderCompany';
import NeedToKnowNewDesign from './NeedToKnowNewDesign';
import { SaveYourQuoteButton } from './SaveYourQuoteButton';
import WhatFigoCovers from './WhatFigoCovers';
import { QuotingFloatingImages } from './QuotingFloatingImages';
import { PlansSection } from './planSection';
import { DeductibleReimbursement } from './customPlanSection';
import { WhatsNotCovered } from './WhatsNotCovered';
import { SeeFullCoverage } from './SeeFullCoverage';
import { useTranslations } from '../../hooks/useTranslations';

import './CardPlanMain.css';
import { usePartnersData } from '../../partners/usePartnersData';

const CardPlanMain = () => {
  const store = useSelector(({ quoting }) => quoting);
  const { t } = useTranslations('quoting');
  const extoleEvents = useExtole();
  const dispatch = useDispatch();
  const { isMobile, isTablet } = useResponsive();
  const {
    isLoading,
    newCustomer: {
      email,
      lastName,
      name,
    },
    parameters,
    petQuoteSelected,
    quoteSelectedValues,
    sessionInformation: { nopCommerceUser },
  } = store;
  const { isPartner, renderCardPlanHeader } = usePartnersData();
  const isAuthenticated = useIsAuthenticated();
  const { loginB2C } = useB2CInfo();
  const location = useLocation();

  const fullName = useMemo(() => {
    if (nopCommerceUser.FirstName) {
      return `${nopCommerceUser.FirstName} ${nopCommerceUser.LastName}`;
    }

    return `${name} ${lastName}`;
  }, [
    lastName,
    name,
    nopCommerceUser.FirstName,
    nopCommerceUser.LastName,
  ]);

  const ESSENTIAL_PLAN = useMemo(() => store.plans[0], [store.plans]);

  const quoteSelected = quoteSelectedValues
    .find((item) => item.quoteId === petQuoteSelected)
    || { petName: 'Pet Name' };

  let plans = [];
  let petQuote = null;

  if (store.data) {
    petQuote = store.data.petQuoteResponseList
      .find((item) => item.petQuoteId === petQuoteSelected);

    plans = petQuote ? petQuote.Plans : [];
  }

  const [isMounted, setIsMounted] = useState(false);
  const [currentZipCode, setCurrentZipCode] = useState('');
  const { petZipCode } = parameters;

  useEffect(() => {
    if (!isMounted || currentZipCode !== petZipCode) {
      setIsMounted(true);
      dispatch(setCurrentQuoteStep(QuotingSteps.SelectPlan.value));
      setCurrentZipCode(petZipCode);
    }
  }, [
    currentZipCode,
    dispatch,
    isMounted,
    petZipCode,
  ]);

  useEffect(() => {
    async function validateCustomerB2C() {
      if (!nopCommerceUser.LoginSuccess && email
        && !isAuthenticated && parameters) {
        const payload = await validateCustomerExistsByEmail(email);
        const { IsValid, Data } = payload;
        const userAlreadyExists = IsValid && !!Data;

        dispatch(validateDiamondUser({
          customerExistsData: Data,
          email,
          userAlreadyExists,
          validateCustomer: false,
        }));
        if (userAlreadyExists) {
          if (!useROPC) {
            const currentUrl = `${location.pathname}${location.search}`;
            loginB2C({
              existingEmail: email,
              urlState: currentUrl,
            });
          }
        }
      }
    }

    validateCustomerB2C();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (store.extoleQuoteUUID || isLoading) {
      return;
    }

    extoleEvents.sendQuoteSubmittedEvent();
    dispatch(extoleQuoteUUIDSave(true));
  }, [dispatch, isLoading, extoleEvents, store.data, store.extoleQuoteUUID]);

  const { getSelectedRate } = useRateSummary();

  useCallbackAfterRate(() => {
    getSelectedRate();
  }, [AFTER_RATE_TAG.createRate, AFTER_RATE_TAG.addPet]);

  const NeedToKnowSection = () => {
    if (useEnrollmentflag && (isMobile || isTablet)) {
      return <NeedToKnowNewDesign />;
    }

    return null;
  };

  return (
    <div
      className={'App-card-plan-main-container '
        + 'Card-plan-main-container-elevated'}
    >
      <div
        className={`${useEnrollmentflag
          ? 'Card-plan-main-container-enrollment '
          : 'Card-plan-main-container '}`
          + 'Card-plan-main-container-margin-top'}
      >
        {renderCardPlanHeader()}

        <section aria-label={t('cardPlanHeader')}>
          <div className="Card-plan-header-title-container">
            <CardPlanMainHeaderCompany isPartner={isPartner} />
          </div>
        </section>

        <PlansSection />

        {isPaycheck ? (
          <span className="Deductible-legend-text">
            Based on 24 pay periods
          </span>
        ) : null}

        {!useCustomPlanPills && (
          <DeductibleReimbursement
            isEssentialSelected={quoteSelected.selectedPlanIndex
              === ESSENTIAL_PLAN.index}
            plans={plans}
            quoteSelected={quoteSelected}
          />
        )}

        {useCustomEffectiveDate && <EffectiveDateCustom name={fullName} />}

        <DiscountApplied />

        <PowerUpSection
          currentZipCode={currentZipCode}
          petQuote={petQuote}
          quoteSelected={quoteSelected}
        />

        <MyPetsContainer />

        {useEnrollmentflag && !isLoading
          && (
            <>
              <WhatFigoCovers />

              <WhatsNotCovered />

              <SeeFullCoverage isVisible={!isMobile && !isTablet} />
            </>
          )}

        <SaveYourQuoteButton />

        <NeedToKnowSection />
      </div>

      <QuotingFloatingImages />

      {!useEnrollmentflag && <NeedToKnowNewDesign />}
    </div>
  );
};

export default CardPlanMain;

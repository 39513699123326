import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { LandingHeader } from './LandingHeader';
import { LandingRightContainer } from './LandingRightContainer';
import { LandingLeftContainer } from './LandingLeftContainer';
import { LandingFooter } from './LandingFooter';
import FigoLoadingOverlay from '../common/FigoLoadingOverlay';
import {
  partnerIdLoaded,
  resetState,
  saveNewPetAge,
  saveNewPetBreed,
  saveNewPetEmail,
  saveNewPetGroupCodeDscr,
  saveNewPetName,
  saveNewPetPromoCode,
  saveNewPetSex,
  saveNewPetType,
  saveNewPetZipCode,
  saveUtmParams,
} from '../../actions';
import {
  formatParameters,
  getUtmParams,
  setSessionStorage,
  removeSessionStorage,
} from '../../util';
import { usePartnerSubId } from '../../hooks';
import { ComingSoonModal } from './ComingSoonModal';
import { VipSupportBanner } from './vipSupportBanner';
import { TemporaryMessage } from '../quoting/TemporaryMessage';

import { usePartnersData } from '../../partners/usePartnersData';
import { PetGender, PetType } from '../../constants';

const LandingPage = () => {
  const dispatch = useDispatch();
  const store = useSelector(({ quoting }) => quoting);
  const location = useLocation();
  const parametersRaw =
    useMemo(() => queryString.parse(location.search), [location.search]);
  const parameters =
    useMemo(() => formatParameters(parametersRaw), [parametersRaw]);
  const disclaimerRef = useRef();
  const [triggerValidation, setTriggerValidation] = useState(false);

  const partnerData = usePartnersData();

  useEffect(() => {
    dispatch(resetState());
    setSessionStorage({ data: {}, key: 'store.quoting' });
    removeSessionStorage('store.mounted');
    const {
      email,
      groupCode,
      groupCodeDscr,
      petName,
      petSex,
      petType,
      petZipCode,
      refer,
    } = parameters || {};

    if (petName) {
      dispatch(saveNewPetName(petName));
    }

    if (petType) {
      let type = null;

      if (PetType.Dog.name === petType) {
        type = PetType.Dog;
      } else if (PetType.Cat.name === petType) {
        type = PetType.Cat;
      }

      if (type) {
        dispatch(saveNewPetType(type));
      }
    }

    if (petSex) {
      let type = null;

      if (PetGender.Female.name === petSex) {
        type = PetGender.Female;
      } else if (PetGender.Male.name === petSex) {
        type = PetGender.Male;
      }

      if (type) {
        dispatch(saveNewPetSex(type));
      }
    }

    if (email) {
      dispatch(saveNewPetEmail(email));
    }

    if (petZipCode) {
      dispatch(saveNewPetZipCode(petZipCode));
    }

    if (groupCode) {
      dispatch(saveNewPetPromoCode(groupCode));
    }

    if (groupCodeDscr) {
      dispatch(saveNewPetGroupCodeDscr(groupCodeDscr));
    }

    setTriggerValidation(!!(petName
      || petSex
      || petType
      || email
      || petZipCode
      || groupCode
      || groupCodeDscr));

    if (refer) {
      dispatch(partnerIdLoaded(refer));
    }

    const utmParams = getUtmParams(parametersRaw);

    if (Object.keys(utmParams).length) {
      dispatch(saveUtmParams(utmParams));
    }
  }, [dispatch, parameters, parametersRaw]);

  // populate age if fond on query params `petAge`
  useEffect(() => {
    if (store.isAgeDataLoading || !parameters.petAge) {
      return;
    }

    const ageFound = store.ageData
      .find(({ Description }) => Description === parameters.petAge);

    if (ageFound) {
      dispatch(saveNewPetAge(ageFound.Description));
    }
  }, [dispatch, store.ageData, store.isAgeDataLoading, parameters.petAge]);

  // populate breed if fond on query params `petBreed`
  useEffect(() => {
    if (store.isBreedDataLoading || !parameters.petBreed) {
      return;
    }

    const breedFound = store.breedData
      .find(({ BreedName }) => BreedName === parameters.petBreed);

    if (breedFound) {
      dispatch(saveNewPetBreed({
        petBreed: breedFound.BreedName,
        petBreedId: breedFound.Id,
      }));
    }
  }, [
    dispatch,
    store.breedData,
    store.isBreedDataLoading,
    parameters.petBreed,
  ]);

  usePartnerSubId();

  const { sessionInformation: {
    validateZipCodeLoading,
  } } = store;

  const showLoadingModal = partnerData.loading || validateZipCodeLoading;
  const {
    isPartner,
    data: {
      CustomFields,
      WelcomeText,
      WelcomeTitle,
    },
  } = partnerData;

  const partnerRowInformation = useMemo(() => {
    if (!CustomFields || !CustomFields.length) {
      return {};
    }

    return {
      detailMessage: CustomFields.find((field) => field.Name
        === 'DetailMessageWelcome'),
      membersInfo: CustomFields.filter((field) => field.Section === 'landing'),
    };
  }, [CustomFields]);

  const LandingBodyContent = () => {
    if (isPartner) {
      return (
        <div className="App-landing-body-content">
          <LandingLeftContainer
            isPartner={isPartner}
            partnerName={WelcomeTitle}
            partnerRowInformation={partnerRowInformation}
            partnerWelcomeText={WelcomeText}
          />

          <div ref={disclaimerRef} />

          <LandingRightContainer
            isPartner
            partnerRowInformation={partnerRowInformation}
            triggerValidation={triggerValidation}
          />
        </div>
      );
    }

    return (
      <main className="App-landing-body-content">
        <LandingRightContainer triggerValidation={triggerValidation} />
      </main>
    );
  };

  return (
    <>
      <div className="App-landing-app">
        <LandingHeader />

        {LandingBodyContent()}

        <TemporaryMessage sticky />
      </div>

      <ComingSoonModal />

      <VipSupportBanner />

      <LandingFooter refer={parameters.refer} />

      <FigoLoadingOverlay visible={showLoadingModal} />
    </>
  );
};

export default LandingPage;

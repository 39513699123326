import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import Loading from '../../common/Loading';
import { PetGender, PetType } from '../../../constants';

import './Prop103DisclaimerText.css';
import { apiCall, getQuoteClientType } from '../../../util';

const PLAN_VALUES = [{
  name: 'Essential',
  value: 5000,
}, {
  name: 'Preferred',
  value: 10000,
}, {
  name: 'Ultimate',
  value: null,
}];

const QuoteLinks = ({ comparableQuote }) => {
  const { t } = useTranslation('quoting');
  const quotes = comparableQuote?.quotes || [];

  return (
    <>
      {quotes.map((item) => (
        <>
          <a
            key={item.linkUrl}
            href={item.linkUrl}
            rel="noreferrer"
            target="_blank"
          >
            {t('clickHere', {
              name: quotes.length === 1 ? '' : `(${item.petName})`,
            })}
          </a>

          <span>{quotes.length === 1 ? ' ' : ', '}</span>
        </>
      ))}
    </>
  );
};

/**
 * Prop 103 requires Insurers in California to inform a client when there are
 * comparable plans to the one they are quoting for at a lower price.
 *
 * @link sdk https://github.com/petpartners/IPG.Prop103
 */
export const Prop103DisclaimerText = () => {
  const store = useSelector(({ quoting }) => quoting, shallowEqual);
  const [comparableQuote, setComparableQuote] = useState({
    displayLink: false,
    quotes: [{ linkUrl: '', petName: '' }],
  });
  const [isQuoteLoading, setQuoteLoading] = useState(false);

  const { petQuoteResponseList, zipCode } = store.data;
  const { isLoading, newCustomer, quoteSelectedValues, partners } = store;

  const logProp103Request = React.useCallback(({
    apiUrl,
    body,
    message,
    response,
    statusCode,
  }) => {
    apiCall('api/Quote/P3/Log', {
      headers: {
        'X-Client-Name': getQuoteClientType(partners),
      },
      post: {
        Endpoint: apiUrl,
        Message: message,
        Request: body,
        Response: response,
        statusCode,
      },
    });
  }, [partners]);

  const payloadQuote = React.useMemo(() => {
    const quotesData = quoteSelectedValues.map((quoteSelected) => {
      const quote = petQuoteResponseList
        .find((item) => item.petQuoteId === quoteSelected.quoteId);

      if (!quote) {
        return {};
      }

      const quotePlan = quote.Plans
        .find((item) => item.Plan === quoteSelected.selectedPlan);
      const quotePrice = quotePlan.RatingOptions
        .find((item) => item.DeductibleId === quoteSelected.deductibleId
          && item.ReimbursementId === quoteSelected.reimbursementId);
      const annualLimit = PLAN_VALUES
        .find((item) => item.name === quotePlan.PlanName);
      const reimbursement =
        +quoteSelected.reimbursementPercentage.replace('%', '');
      const years = quote.petAgeName.includes('months')
        ? 0 : quote.petAgeName.replace(/[a-z]/g, '').trim();

      return {
        accidentOnly: false,
        annualLimit: annualLimit?.value,
        breedId: `${quote.breedId}`,
        deductible: +quoteSelected.deductibleQuantity
          .replace('$', '').replace(',', ''),
        email: newCustomer.email,
        gender: quote.gender === PetGender.Male.value ? 0 : 1,
        petAge: +years,
        petName: quoteSelected.petName,
        petType: quote.petType === PetType.Dog.value ? 'dog' : 'cat',
        premiumMonthly: quotePrice.MonthlyPremium,
        reimbursement: reimbursement / 100,
        requestingInsurer: 'figo',
        zipCode,
      };
    });

    return quotesData;
  }, [
    newCustomer.email,
    petQuoteResponseList,
    quoteSelectedValues,
    zipCode,
  ]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    setQuoteLoading(true);

    if (window.apiClient) {
      const promises = payloadQuote
        .map((data) => new Promise((resolve, reject) => {
          window.apiClient.makeRequest(data)
            .then((response) => {
              const { apiUrl, errorMsg, statusCode } = response;
              const displayLink = response.data?.displayLink || '';
              const linkUrl = response.data?.linkUrl || '';

              logProp103Request({
                apiUrl,
                body: data,
                message: errorMsg,
                response,
                statusCode,
              });
              resolve({ displayLink, linkUrl, petName: data.petName });
            }).catch((error) => { reject(error); });
        }));

      Promise.all(promises).then((quotes) => {
        const quotesToDisplay = quotes.filter((item) => item.displayLink);
        const displayLink = quotesToDisplay.length > 0;

        if (displayLink) {
          setComparableQuote({ displayLink: true, quotes: quotesToDisplay });
        }

        setQuoteLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, zipCode]);

  if (isQuoteLoading) {
    return (
      <div className="Prop103-loading">
        <Loading border="3px" size="33px" />
      </div>
    );
  }

  if (!comparableQuote.displayLink) {
    return null;
  }

  return (
    <p className="Prop103-disclaimer-text">
      <Trans
        components={{
          a: <QuoteLinks comparableQuote={comparableQuote} />,
        }}
        i18nKey="californiaDisclaimer"
        ns="quoting"
      />
    </p>
  );
};

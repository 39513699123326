import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import queryString from 'query-string';

import {
  closeSaveQuoteModalConfirmation,
  forgetPasswordVisibleChanged,
  loadMultiModalData,
  newCustomerChanged,
  noDiamondClientIdError,
  saveSelectedAddPetModal,
  saveSelectedEditPetModal,
} from '../../../actions';

import {
  changeProceedWithoutCostcoMembership,
} from '../../../actions/partners';

import figoLogo from '../../../assets/figo-logo.svg';
import figoLogoSmall from '../../../assets/figo-logo-round.svg';

import { YourInfoContent } from '../yourInfo/YourInfoContent';
import ModalContainer from '../../common/ModalContainer';
import { ModalAddPet } from '../../common/modalAddPet/ModalAddPet';
import ModalRemovePet from '../ModalRemovePet';
import MultiModalInfo from '../../common/MultiModalInfo';
import { SaveYourQuoteModal } from '../SaveYourQuoteModal';
import SidebarSaveQuoteModalConfirmation
from '../SidebarSaveQuoteModalConfirmation';
import { ComingSoonModal } from '../../landing/ComingSoonModal';
import { HeaderIAIC } from './HeaderIAIC';
import { QuotingStepsComponent } from './QuotingStepsComponent';
import { MyQuote } from './MyQuote';
import { StickyHeader } from './StickyHeader';
import { PriceStickyBar } from '../purchase/PriceStickyBar';
import { AnimatedFloatingHeader } from './AnimatedFloatingHeader';
import { QuoteContent } from '../QuoteContent';
import FigoLoadingOverlay from '../../common/FigoLoadingOverlay';

import {
  createEffectiveDate,
  forceFocusElement,
  isPaycheck,
  useMarketingChannel,
  useROPC,
} from '../../../util';
import { MODAL_RELATION_LIST, QuotingSteps } from '../../../constants';
import { URL_ROOT_QUOTE, URL_YOUR_INFO } from '../../../routes';
import ModalCustomerWelcome from '../../common/ModalCustomerWelcome';
import ModalCustomerLogin from '../../common/ModalCustomerLogin';
import { dataLayerViewItem } from '../../../gtmDataLayer';

import './css/QuotingHeader.css';
import ModalForgetPassword from '../../common/ModalForgetPassword';
import { NEW_CUSTOMER_EMAIL_CHANGED } from '../../../actions/types';

function QuotingHeader() {
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const parametersRaw = queryString.parse(location.search);
  const store = useSelector(({ quoting }) => quoting);
  const [figoSmallLogoVisible, setFigoSmallLogoVisible] = useState(false);
  const [myQuoteFlatCorner, setMyQuoteFlatCorner] = useState(false);
  const [isListenerActive, setIsListenerActive] = useState(false);
  const [showModalLogin, setShowModalLogin] = useState(false);
  const {
    addPetModalVisible,
    currentQuoteStep,
    customerZipCode,
    editPetModalVisible,
    isLoading,
    isMonthlyPlan,
    newCustomer,
    multiModalData: { ZipCode },
    multiModalUserSelection: {
      needToKnowIdSelected,
      needToKnowModalVisible,
      petCoPayIdSelected,
      petCoPayModalVisible,
      termsAndCondIdSelected,
      termsAndCondModalVisible,
      wellnessIdSelected,
      wellnessModalVisible,
    },
    loggingWithoutModal,
    partners: {
      data: { DiscountLabel },
      isCostco,
      partnerId,
    },
    parameters,
    removePetModalVisible,
    saveQuote: {
      savedQuoteModalVisible,
    },
    sessionInformation,
  } = store;

  const {
    isCustomer,
    loadingLogin,
    nopCommerceUser,
    showComingSoonModal,
    userEmail,
  } = store.sessionInformation;

  const {
    NEED_TO_KNOW_MODAL_ID,
    PET_CO_PAY_MODAL_ID,
    TERMS_AND_COND_MODAL_ID,
    WELLNESS_MODAL_ID,
  } = MODAL_RELATION_LIST;

  const isSecondStep = currentQuoteStep === QuotingSteps.YourInfo.value;

  const styles = useMemo(() => {
    if (Object.entries(parametersRaw).length || isSecondStep) {
      return {
        bodyStyle: 'App-body-quote',
      };
    }

    return {
      bodyStyle: '',
    };
  }, [isSecondStep, parametersRaw]);

  const showLoadingOverlay = useMemo(() => isLoading
    || store.isLoadingNewAddress
    || store.purchase.loading
    || store.rateSummary.loading
    || store.sessionInformation.isLoading, [
    isLoading,
    store.isLoadingNewAddress,
    store.purchase.loading,
    store.rateSummary.loading,
    store.sessionInformation.isLoading,
  ]);

  const handleScroll = () => {
    if (document.body) {
      setFigoSmallLogoVisible(
        document.body.getBoundingClientRect().top <= -210,
      );
      setMyQuoteFlatCorner(
        document.body.getBoundingClientRect().top <= -140,
      );
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  useEffect(() => {
    if (store.data && !isListenerActive) {
      history.listen((navigationData) => {
        if (history.action === 'POP'
          && navigationData.pathname === URL_YOUR_INFO) {
          setIsListenerActive(true);
          dispatch(changeProceedWithoutCostcoMembership({
            diamondClientId: nopCommerceUser.DiamonClientdId,
            effectiveDateCustom: store.effectiveDateCustom,
            eMail: userEmail || parameters.email,
            isProceedWithoutPartner: !isCostco,
            nopCommerceClientId: nopCommerceUser.CustomerNopCommerceId,
            parameters,
            petQuoteList: store.data.petQuoteResponseList,
            quoteId: store.petQuoteSelected,
          }));
        }
      });
    }
  }, [
    dispatch,
    history,
    isCostco,
    isListenerActive,
    nopCommerceUser.CustomerNopCommerceId,
    nopCommerceUser.DiamonClientdId,
    parameters,
    store.data,
    store.effectiveDateCustom,
    store.petQuoteSelected,
    userEmail,
  ]);

  const handleCloseModalAddPet = useCallback(() => {
    dispatch(saveSelectedEditPetModal(false));
    dispatch(saveSelectedAddPetModal(false));
    const { petQuoteSelected, data: { petQuoteResponseList } } = store;
    const currentQuote = (petQuoteResponseList || [])
      .find((quote) => quote.petQuoteId === petQuoteSelected);
    forceFocusElement(`petQuoteDropDownItem${currentQuote.petQuoteId}-header`);
  }, [dispatch, store]);

  const handleOnClickCloseConfirmationModal = () => {
    dispatch(closeSaveQuoteModalConfirmation());
  };

  useEffect(() => {
    if (!customerZipCode || customerZipCode === ZipCode) {
      return;
    }

    dispatch(loadMultiModalData({
      effectiveDate: createEffectiveDate(),
      isEBProduct: false,
      zipCode: customerZipCode,
    }));
  }, [customerZipCode, dispatch, ZipCode]);

  // GTM
  useEffect(() => {
    dataLayerViewItem({
      pathname: location.pathname,
      petIndex: store.petQuoteSelected,
    });
  }, [location.pathname, store.petQuoteSelected]);

  const headerIAICClassName = useMemo(() => {
    if (currentQuoteStep === QuotingSteps.SelectPlan.value) {
      return '';
    }

    return 'Quoting-header-your-info';
  }, [currentQuoteStep]);

  function renderHeaderContent() {
    const isRootQuote = location.pathname.toLocaleLowerCase()
      === URL_ROOT_QUOTE;

    if (isRootQuote) {
      return <AnimatedFloatingHeader isSecondStep={isSecondStep} />;
    }

    return (
      <header
        aria-label="Site header"
        className="Quoting-header-container"
        role="banner"
      >
        <HeaderIAIC
          classContainer={headerIAICClassName}
        />

        <div className="Quoting-header-container-row-top">
          <div className="Quoting-header-logo-image-container">
            <img
              alt="Figo Logo"
              className={`Quoting-header-logo-image
              ${figoSmallLogoVisible && ' Quoting-header-gone'}`}
              src={figoLogo}
            />
          </div>

          <QuotingStepsComponent
            currentQuoteStep={currentQuoteStep}
            figoSmallLogoVisible={figoSmallLogoVisible}
            isPaycheck={isPaycheck}
            searchParameters={store.searchParameters}
          />
        </div>

        <div className="Quoting-header-container-row-bottom">
          <img
            alt=""
            className={`Quoting-header-logo-image-small
            ${!figoSmallLogoVisible && ' Quoting-header-gone'}`}
            src={figoLogoSmall}
          />

          <MyQuote
            isMonthlyPlan={isMonthlyPlan}
            isPaycheck={isPaycheck}
            myQuoteFlatCorner={myQuoteFlatCorner}
            quoteSelectedValues={store.quoteSelectedValues}
          />

          {store.data && (
            <StickyHeader
              discountLabel={DiscountLabel}
              isPartnerId={!!partnerId}
              isSecondStep={isSecondStep}
              petQuoteResponseList={store.data.petQuoteResponseList}
            />
          )}
        </div>
      </header>
    );
  }

  const onModalClosed = useCallback(() => {
    setShowModalLogin(true);
  }, []);

  useEffect(() => {
    if (useMarketingChannel) {
      return;
    }

    // Free user don't have DiamondClientId only CustomerId
    const isFreeUser = sessionInformation.isCustomer
      && !nopCommerceUser.DiamonClientdId
      && nopCommerceUser.CustomerNopCommerceId;
    const isLoadingSessionInformation = sessionInformation.isLoading
      || sessionInformation.loadingLogin;

    if (!isSecondStep
      || !isFreeUser
      || isLoadingSessionInformation) {
      return;
    }

    dispatch(noDiamondClientIdError('User without diamondId.'));
  }, [
    isSecondStep,
    dispatch,
    sessionInformation,
    nopCommerceUser.DiamonClientdId,
    nopCommerceUser.CustomerNopCommerceId,
  ]);

  useEffect(() => {
    if (newCustomer.email && isCustomer && !nopCommerceUser.LoginSuccess
      && (isSecondStep || (!isSecondStep && !loggingWithoutModal))) {
      setShowModalLogin(true);
      dispatch(forgetPasswordVisibleChanged({ visible: false }));
    }

    if (nopCommerceUser.LoginSuccess) {
      setShowModalLogin(false);
    }
  }, [dispatch, isCustomer, newCustomer.email, nopCommerceUser]);

  useEffect(() => {
    setShowModalLogin(false);
  }, [showComingSoonModal]);

  const onDismissLoginModal = useCallback(() => {
    if (!loadingLogin) {
      dispatch(newCustomerChanged({
        type:
          NEW_CUSTOMER_EMAIL_CHANGED,
        value: '',
      }));
      setShowModalLogin(false);
    }
  }, [dispatch, loadingLogin]);

  const handleForgetPasswordClick = useCallback(() => {
    if (!loadingLogin) {
      setShowModalLogin(false);
      dispatch(forgetPasswordVisibleChanged({ visible: true }));
    }
  }, [dispatch, loadingLogin]);

  return (
    <>
      <HeaderIAIC
        classContainer={isSecondStep ? 'Gone' : headerIAICClassName}
      />

      {renderHeaderContent()}

      <main className={`App-body ${styles.bodyStyle}`}>
        <Switch>
          <Route exact path={match.path}>
            <QuoteContent />
          </Route>

          <Route path={`${match.path}/yourInfo/:scrollToTop?`}>
            <YourInfoContent showLoadingOverlay={showLoadingOverlay} />
          </Route>
        </Switch>
      </main>

      <PriceStickyBar />

      <ModalContainer
        cancelable={false}
        show={addPetModalVisible || editPetModalVisible}
      >
        <ModalAddPet
          handleCloseModalAddPet={handleCloseModalAddPet}
          isEditing={editPetModalVisible}
          show={addPetModalVisible || editPetModalVisible}
        />
      </ModalContainer>

      {useROPC ? (
        <ModalContainer
          cancelable={false}
          handleOutClick={onDismissLoginModal}
          show={!showLoadingOverlay && showModalLogin
            && !showComingSoonModal}
        >
          <ModalCustomerLogin
            emailUser={newCustomer.email}
            onDismiss={onDismissLoginModal}
            onForgotPassword={handleForgetPasswordClick}
          />
        </ModalContainer>
      ) : null}

      <ModalCustomerWelcome />

      <ModalForgetPassword onModalClosed={onModalClosed} />

      <ComingSoonModal />

      <ModalContainer
        cancelable={false}
        show={removePetModalVisible}
      >
        <ModalRemovePet />
      </ModalContainer>

      <MultiModalInfo
        menuSelected={needToKnowIdSelected}
        modalId={NEED_TO_KNOW_MODAL_ID}
        show={needToKnowModalVisible}
      />

      <MultiModalInfo
        menuSelected={termsAndCondIdSelected}
        modalId={TERMS_AND_COND_MODAL_ID}
        show={termsAndCondModalVisible}
      />

      <MultiModalInfo
        menuSelected={wellnessIdSelected}
        modalId={WELLNESS_MODAL_ID}
        show={wellnessModalVisible}
      />

      {/* FPA-11163 */}
      <MultiModalInfo
        menuSelected={petCoPayIdSelected}
        modalId={PET_CO_PAY_MODAL_ID}
        show={petCoPayModalVisible}
      />

      <MultiModalInfo
        menuSelected={-1}
        show={store.multiModalCustomData}
      />

      <SaveYourQuoteModal />

      <SidebarSaveQuoteModalConfirmation
        handleOnClick={handleOnClickCloseConfirmationModal}
        show={savedQuoteModalVisible}
      />

      <FigoLoadingOverlay visible={showLoadingOverlay} />

      {/* OneInc modal create here */}
      <div id="portalOneContainer" />
    </>
  );
}

export { QuotingHeader };

import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  removePet,
  saveSelectedEditPetModal,
  saveSelectedPetNumber,
  saveSelectedRemovePetModal,
} from '../../../../actions';

const usePetQuoteDropdown = ({ petQuote = {}, toggleOpen = () => { } }) => {
  const dispatch = useDispatch();
  const store = useSelector(({ quoting }) => quoting);
  const {
    data,
    newCustomer: { lastName, name },
    parameters,
    petQuoteSelected,
    petRemovedLoading,
    quoteSelectedValues,
    sessionInformation: { nopCommerceUser, userEmail },
  } = store;

  const quoteSelected = quoteSelectedValues
    .find((item) => item.quoteId === petQuote.petQuoteId) || {};

  function onEditPet() {
    const { quoteId } = quoteSelected;

    dispatch(saveSelectedPetNumber(quoteId));
    dispatch(saveSelectedEditPetModal(true));
    toggleOpen();
  }

  const fullName = useMemo(() => {
    if (nopCommerceUser.FirstName) {
      return `${nopCommerceUser.FirstName} ${nopCommerceUser.LastName}`;
    }

    return `${name} ${lastName}`;
  }, [
    lastName,
    name,
    nopCommerceUser.FirstName,
    nopCommerceUser.LastName,
  ]);

  function onRemovePet() {
    if (petRemovedLoading) {
      return;
    }

    const petQuoteList = data.petQuoteResponseList
      .filter((element) => element.petQuoteId !== petQuote.petQuoteId);

    let petSelected = petQuoteList
      .find((element) => element.petQuoteId === petQuoteSelected);

    const newQuoteSelectedValues = quoteSelectedValues
      .filter((element) => element.quoteId !== petQuote.petQuoteId);

    if (petQuoteSelected === petQuote.petQuoteId) {
      petSelected = petQuoteList
        .find((element) => element.petQuoteId !== petQuoteSelected);
    }

    dispatch(removePet({
      diamondClientId: nopCommerceUser.DiamonClientdId,
      effectiveDateCustom: store.effectiveDateCustom,
      eMail: userEmail || parameters.email,
      employerName: fullName,
      modifiers: petSelected.modifiers,
      nopCommerceClientId: nopCommerceUser.CustomerNopCommerceId,
      parameters,
      petQuoteList,
      promoCode: store.newPet.promoCode,
      quoteId: petSelected.petQuoteId,
      quoteSelectedValues: newQuoteSelectedValues,
      removePetId: petQuote.petQuoteId,
      toggleOpen,
    }));
  }

  function onRemovePetAskConfirmation() {
    if (petRemovedLoading) {
      return;
    }

    const { quoteId } = quoteSelected;

    dispatch(saveSelectedPetNumber(quoteId));
    dispatch(saveSelectedRemovePetModal(true));
  }

  return {
    onEditPet,
    onRemovePet,
    onRemovePetAskConfirmation,
    petRemovedLoading,
    quoteSelected,
  };
};

export { usePetQuoteDropdown };

import React from 'react';
import './GoodDogDiscountLabel.css';
import { useTranslations } from '../../hooks';

const GoodDogDiscountLabel = ({ logo }) => {
  const { t } = useTranslations('quoting');

  return (
    <div className="Good-dog-discount-label">
      <div className="Good-dog-discount-label-container">
        <img alt="" className="Good-dog-discount-image" src={logo} />

        <span>{t('goodDog.discount')}</span>
      </div>
    </div>
  );
};

export { GoodDogDiscountLabel };

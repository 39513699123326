import React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import {
  loadQuoteData,
  loadQuoteDataByOrderId,
  loadQuoteDataByStorage,
  saveNewPetPickupDate,
  saveURLParameters,
  saveUtmParams,
} from './actions';
import { QuotingHeader } from './components/quoting/header/QuotingHeader';
import QuotingFooter from './components/quoting/QuotingFooter';
import PageNotFound from './components/pageNotFound/PageNotFound';
import './App.css';
import { formatParameters, getSessionStorage, getUtmParams } from './util';
import OpenQuote from './components/quoting/OpenQuote';
import LandingPage from './components/landing/LandingPage';
import GenericErrorModalContainer
from './components/common/GenericErrorModalContainer';
import PurchaseSuccess from './components/quoting/purchase/PurchaseSuccess';
import PurchaseError from './components/quoting/purchase/PurchaseError';
import Logout from './components/common/Logout';
import { PageTabTitle } from './components/common/PageTabTitle';
import { SavedQuoteExpired } from './components/saveQuote/SavedQuoteExpired';
import { LoginAccountScreen } from './components/login/LoginAccountScreen';
import {
  TrackingLoadQuoteOnRefresh,
} from './components/common/TrackingLoadQuoteOnRefresh';

import {
  LANDING_URL,
  URL_COSTCO_CX,
  URL_COSTCO,
  URL_GOOD_DOG,
  URL_LOGIN_ACCOUNT,
  URL_LOGOUT,
  URL_OPEN_QUOTE_ERROR,
  URL_OPEN_QUOTE,
  URL_PURCHASE_BACKEND_ERROR,
  URL_PURCHASE_ERROR,
  URL_PURCHASE_RETRY,
  URL_PURCHASE,
  URL_ROOT_QUOTE,
  URL_YOUR_INFO,
  URL_USAA,
} from './routes';
import {
  PurchaseRetryScreen,
} from './components/quoting/purchase/PurchaseRetryScreen';
import {
  StickyBarHeightProvider,
} from './components/landing/StickyBarHeightProvider';
import {
  COSTCO_CX_PARTNER_ID,
  COSTCO_PARTNER_ID,
  GOOD_DOG_PARTNER_ID,
  USAA_PARTNER_ID,
} from './partners/partners.constants';
import { FeatureFlagProvider, useGTM } from './hooks';
import {
  GoodDogComingSoonModal,
} from './components/landing/GoodDogComingSoonModal';
import {
  ModalMultiQuoteLoading,
} from './components/quoting/purchase/ModalMultiQuoteLoading';

const URLS_VALID = [
  URL_LOGIN_ACCOUNT,
  URL_PURCHASE_RETRY,
  URL_PURCHASE,
  URL_ROOT_QUOTE,
  URL_YOUR_INFO,
];

const COSTCO_URL_PARAMS = { refer: COSTCO_PARTNER_ID };
const COSTCO_CX_URL_PARAMS = { refer: COSTCO_CX_PARTNER_ID };
const GOOD_DOG_URL_PARAMS = { refer: GOOD_DOG_PARTNER_ID };
const USAA_URL_PARAMS = { refer: USAA_PARTNER_ID };

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  useGTM();
  const parametersRaw = queryString.parse(location.search);
  const parameters = formatParameters(parametersRaw);
  const utmParams = getUtmParams(parametersRaw);

  function isMounted() {
    const storageMounted = getSessionStorage('store.mounted');

    return !!storageMounted;
  }

  function validParameters() {
    if (location.pathname.toLowerCase() !== URL_ROOT_QUOTE && isMounted()) {
      return true;
    }

    if (parameters && parameters.orderId) {
      return true;
    }

    return parameters && parameters.petName
      && parameters.petType && parameters.petSex && parameters.petBreed
      && parameters.petAge && parameters.petZipCode && parameters.email;
  }

  function isValidUrl() {
    return URLS_VALID.includes(location.pathname.toLowerCase());
  }

  function validLoadCache({ storageData }) {
    if (
      storageData
      && location.search
      && storageData.searchParameters === location.search
    ) {
      return true;
    }

    return location.pathname.toLowerCase() !== URL_ROOT_QUOTE && isMounted();
  }

  const RedirectWithParameters = ({ url, urlParams = {} }) => {
    const params = { ...urlParams, ...utmParams };

    dispatch(saveUtmParams(utmParams));

    return (
      <Redirect
        to={`${url}?${queryString.stringify(params)}`}
      />
    );
  };

  if (isValidUrl()) {
    if (validParameters()) {
      const storageData = getSessionStorage('store.quoting');
      const validStoredQuote = validLoadCache({ storageData });

      if (validStoredQuote) {
        // Reset some props state to default
        const state = {
          ...storageData,
          oneInc: {
            ...storageData.oneInc,
            portalOneSessionKey: '',
          },
          powerUpLoadings: [],
          sessionInformation: {
            ...storageData.sessionInformation,
            loadingLogin: false,
            showLoginMenssage: false,
            showWelcomeMessage: false,
          },
        };

        dispatch(loadQuoteDataByStorage(state));
      } else {
        if (parameters.orderId) {
          dispatch(loadQuoteDataByOrderId(parameters));
        } else {
          dispatch(loadQuoteData({ firstLoad: true, parameters }));
        }

        dispatch(saveUtmParams(utmParams));

        if (parameters.petPickupDate) {
          dispatch(saveNewPetPickupDate(parameters.petPickupDate));
        }

        dispatch(saveURLParameters({ parameters, search: location.search }));
      }
    } else {
      let landingUrl = LANDING_URL;
      if (parameters.groupCode) {
        landingUrl += `?groupCode=${parameters.groupCode}`;
      }
      history.push(landingUrl);
    }
  }

  return (
    <FeatureFlagProvider>
      <PageTabTitle />

      <TrackingLoadQuoteOnRefresh />

      <Switch>
        <Route exact path="/">
          <RedirectWithParameters url={LANDING_URL} />
        </Route>

        <Route exact path={URL_COSTCO}>
          <RedirectWithParameters
            url={LANDING_URL}
            urlParams={COSTCO_URL_PARAMS}
          />
        </Route>

        <Route exact path={URL_COSTCO_CX}>
          <RedirectWithParameters
            url={LANDING_URL}
            urlParams={COSTCO_CX_URL_PARAMS}
          />
        </Route>

        <Route exact path={URL_GOOD_DOG}>
          <RedirectWithParameters
            url={LANDING_URL}
            urlParams={GOOD_DOG_URL_PARAMS}
          />
        </Route>

        <Route exact path={URL_USAA}>
          <RedirectWithParameters
            url={LANDING_URL}
            urlParams={USAA_URL_PARAMS}
          />
        </Route>

        <Route exact path={URL_LOGIN_ACCOUNT}>
          <LoginAccountScreen />
        </Route>

        <Route exact path={URL_LOGOUT}>
          <Logout />
        </Route>

        <Route path={URL_ROOT_QUOTE}>
          <div className="App">
            <StickyBarHeightProvider>
              <QuotingHeader />

              <QuotingFooter />
            </StickyBarHeightProvider>
          </div>
        </Route>

        <Route path={URL_PURCHASE}>
          <PurchaseSuccess />
        </Route>

        <Route path={URL_PURCHASE_BACKEND_ERROR}>
          <PurchaseError />
        </Route>

        <Route path={URL_PURCHASE_RETRY}>
          <PurchaseRetryScreen />
        </Route>

        <Route path={URL_PURCHASE_ERROR}>
          <PurchaseSuccess />
        </Route>

        <Route path={LANDING_URL}>
          <LandingPage />
        </Route>

        <Route exact path={URL_OPEN_QUOTE}>
          <OpenQuote />
        </Route>

        <Route path={URL_OPEN_QUOTE_ERROR}>
          <SavedQuoteExpired />
        </Route>

        <Route component={PageNotFound}>
          <PageNotFound />
        </Route>
      </Switch>

      <ModalMultiQuoteLoading />

      <GenericErrorModalContainer />

      <GoodDogComingSoonModal />
    </FeatureFlagProvider>
  );
}

export default App;

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import continueToPaymentsArrow
from '../../../assets/arrow-right-citron-main.svg';
import ill4 from '../../../assets/ills/ill-4.svg';

import TouchableDiv from '../../common/TouchableDiv';
import FigoLottie from '../../common/FigoLottie';
import { PurchaseStickyBar } from './PurchaseStickyBar';

import { useROPC, useTrackingEvents } from '../../../util';
import {
  forceValdationDiamonUser,
  validateDiamondUser,
  validateRecaptchaToken,
} from '../../../actions';
import { useTrackingPurchaseComplete } from '../../../trackingEvents';
import {
  URL_PURCHASE,
  URL_PURCHASE_BACKEND_ERROR,
  URL_PURCHASE_ERROR,
  URL_YOUR_INFO,
} from '../../../routes';
import { resetOneInInformation } from '../../../actions/oneInc';
import { useB2CInfo, useInjectScript, usePurchase } from '../../../hooks';

import './YourInfoFooter.css';

const ORIGIN = {
  costco: 3,
  d2c: 1,
  goodDog: 4,
};
// https://developers.google.com/recaptcha/docs/v3
const RECAPTCHA_SRC = 'https://www.google.com/recaptcha/api.js';

const YourInfoFooter = ({
  handleContinuePurchaseClick = () => { },
  isPurchaseValid = false,
  isSecondStep = false,
  petCloudLoginSuccess,
}) => {
  const store = useSelector(({ quoting }) => quoting, shallowEqual);
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    customerValidationLoading,
    purchase,
    recaptcha,
    searchParameters,
    sessionInformation,
  } = store;
  const { isCustomer, nopCommerceUser, userEmail } = sessionInformation;
  const { loginB2C } = useB2CInfo();
  const { doPurchase } = usePurchase();

  const scriptRecaptchaLoaded = useInjectScript({
    force: true,
    id: 'reCAPTCHAv3',
    src: RECAPTCHA_SRC,
  });

  const { trackPurchaseComplete } = useTrackingPurchaseComplete();

  useEffect(() => {
    if (purchase.error && !purchase.success) {
      history.push(URL_PURCHASE_BACKEND_ERROR);

      return;
    }

    if (!purchase.error && purchase.success) {
      if (useTrackingEvents) {
        const effectiveDate =
          store.effectiveDateCustom || store.data.effectiveDate;
        trackPurchaseComplete({
          effectiveDate,
          policies: store.purchase.policyNumbers,
        });
      }

      const goToPurchaseScreen = async () => {
        const url = purchase.incompletePurchases?.length > 0
          ? URL_PURCHASE_ERROR : URL_PURCHASE;

        await dispatch(resetOneInInformation());
        history.push(url);
      };

      goToPurchaseScreen();
    }
  }, [
    history,
    purchase.success,
    purchase.error,
    purchase.incompletePurchases,
    store,
    trackPurchaseComplete,
    dispatch,
  ]);

  useEffect(() => {

  }, []);

  function handleReturnToPlansClick() {
    const url = `/quoting/getQuote${searchParameters}`;
    history.push(url);
  }

  function renderContentButton() {
    const title = isSecondStep ? 'Purchase' : 'Continue';
    const className = isSecondStep ? 'Your-info-continue-to-payments-arrow'
      : 'Your-info-continue-to-step-arrow';

    if (customerValidationLoading || recaptcha.loading) {
      return (
        <FigoLottie
          height={30}
          width={30}
        />
      );
    }

    return (
      <>
        <span className="Your-info-continue-to-payments-label">{title}</span>

        <img
          alt=""
          aria-hidden
          className={className}
          src={continueToPaymentsArrow}
        />
      </>
    );
  }

  const onContinueWithoutLoginClick = ({ url = '' }) => () => {
    history.push(url);
  };

  function forceNopCommerUser() {
    dispatch(forceValdationDiamonUser(userEmail));
  }

  const handleOnPurchaseQuote = () => {
    const {
      newCustomer,
      partners,
    } = store;
    const { email, emailValidated } = newCustomer;
    const { costcoMembership, isCostco } = partners;

    if (!emailValidated) {
      dispatch(validateDiamondUser({
        email,
      }));
      return;
    }

    if (handleContinuePurchaseClick && !customerValidationLoading) {
      handleContinuePurchaseClick();
    }

    if (!isSecondStep && !petCloudLoginSuccess) {
      return;
    }

    if (!isSecondStep || !isPurchaseValid) {
      return;
    }

    if (!nopCommerceUser.LoginSuccess && isCustomer && !useROPC) {
      forceNopCommerUser();

      loginB2C({
        continueWithoutLogin: onContinueWithoutLoginClick({
          url: URL_YOUR_INFO,
        }),
        existingEmail: userEmail,
        urlState: URL_YOUR_INFO,
      });

      return;
    }

    if (isCostco && !costcoMembership.isValid) {
      return;
    }

    if (!isPurchaseValid || store.purchase.loading) {
      return;
    }

    doPurchase();
  };

  async function onSubmit(token) {
    const { partners } = store;
    const { isCostco, partnerId } = partners;
    const { loading } = store.recaptcha;

    if (loading) {
      return;
    }

    let origin = ORIGIN.d2c;

    if (partnerId) {
      origin = isCostco ? ORIGIN.costco : ORIGIN.goodDog;
    }

    const isValidToken = await dispatch(validateRecaptchaToken({
      origin,
      token,
      user: userEmail,
    }));

    if (isValidToken) {
      handleOnPurchaseQuote();
    }
  }

  useEffect(() => {
    window.onSubmit = onSubmit;
  }, [store]);

  function renderContinuePurchasePaymentButton() {
    if (isSecondStep && !store.oneInc.tokenId) {
      return null;
    }

    if (!scriptRecaptchaLoaded) {
      return null;
    }

    return (
      <>
        {isSecondStep
          ? (
            <button
              className="Your-info-continue-to-payments-button"
              id="continueToPaymentsBtn"
              onClick={handleOnPurchaseQuote}
              type="button"
            >
              {renderContentButton()}
            </button>
          ) : (
            <button
              className="g-recaptcha Your-info-continue-to-payments-button"
              data-action="submit"
              data-callback="onSubmit"
              data-sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
              id="continueToPaymentsBtn"
              type="submit"
            >
              {renderContentButton()}
            </button>
          )}
      </>
    );
  }

  if (isSecondStep) {
    return (
      <PurchaseStickyBar
        disabled={!isPurchaseValid}
        onPurchaseClick={isSecondStep ? handleOnPurchaseQuote
          : handleContinuePurchaseClick}
      />
    );
  }

  return (
    <>
      <div
        className={'Your-info-footer-container'
          + ' Your-info-footer-container-enrollment'}
      >
        <div className="Your-info-footer-buttons-container">
          <TouchableDiv
            className={'Your-info-return-to-plans-button'
              + ' Your-info-return-to-plans-button-enrollment'}
            id="returnToPlansBtn"
            onClick={handleReturnToPlansClick}
          >
            <span
              aria-hidden="true"
              className="Your-info-return-to-plans-label"
            >
              ↵ &nbsp;
            </span>

            <span className="Your-info-return-to-plans-label">
              Back to Customize
            </span>
          </TouchableDiv>

          {renderContinuePurchasePaymentButton()}

          {isSecondStep && (
            <img
              alt=""
              aria-hidden
              className="Ill-image Your-info-image-3 No-mobile No-tablet"
              src={ill4}
            />
          )}
        </div>
      </div>
    </>
  );
};

export { YourInfoFooter };
